import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import Markdown from 'markdown-to-jsx';

import { alpha, makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core';

import { TermsConditionsEn } from './TermsConditionsEn';
import { TermsConditionsEs } from './TermsConditionsEs';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 640,
      padding: 15,
    },
    '& .MuiDialogContent-root': {
      textAlign: 'justify',
      '@media (max-width:540px)': {
        textAlign: 'left',
      },
      '& div > p:first-child': {
        textAlign: 'left',
      },
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
  label: {
    color: alpha(theme.palette.text.primary, 0.54),
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
}));

export const RegisterTermsConditionsDialog = ({
  open,
  onClose,
  termsDisabled,
  setTermsDisabled,
  setTermsChecked,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const acceptTerms = () => {
    setTermsDisabled(false);
    setTermsChecked(true);
    onClose();
  };

  const declineTerms = () => {
    setTermsChecked(false);
    onClose();
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>{t('conditions.of.use')}</DialogTitle>
      <DialogContent>
        { ['en'].includes(i18n.language) && <Markdown>{TermsConditionsEn}</Markdown> }
        { ['es', 'ca'].includes(i18n.language) && <Markdown>{TermsConditionsEs}</Markdown> }
        <DialogActions>
          <Button
            className={classes.button}
            onClick={declineTerms}
            variant="outlined"
            color="primary"
          >
            {termsDisabled ? t('decline') : t('close')}
          </Button>
          <Button
            className={classes.button}
            onClick={acceptTerms}
            disabled={!termsDisabled}
            variant="contained"
            color="primary"
          >
            {termsDisabled ? t('accept') : t('read.and.accepted')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
