import React from 'react';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  labelRoot: {
    marginRight: 3,
  },
}));

export const CheckBoxUI = ({
  children,
  name,
  label,
  props,
  checked = false,
  disabled = false,
}) => {
  const { handleChange, handleMouseEnter, handleMouseLeave } = props;
  const classes = useStyles();

  return (
    <Box>
      <FormControlLabel
        classes={{ root: classes.labelRoot }}
        control={(
          <Checkbox
            type="checkbox"
            color="primary"
            checked={checked}
            onChange={handleChange}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            name={name}
            disabled={disabled}
          />
        )}
        label={label}
      />
      {children}
    </Box>
  );
};
