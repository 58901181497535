import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  helperTextRoot: {
    color: theme.palette.error.main,
  },
}));

const TEXT = 'text';
const PASSWORD = 'password';

export const PasswordFieldUI = ({ name, label, props }) => {
  const { values, handleChange, errors } = props;
  const [showPassword, setShow] = useState(false);
  const classes = useStyles();

  const handleShowPassword = () => {
    setShow(!showPassword);
  };

  return (
    <FormControl>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Input
        id={name}
        type={showPassword ? TEXT : PASSWORD}
        value={values[name]}
        onChange={handleChange}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              onClick={handleShowPassword}
              onMouseDown={handleShowPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )}
      />
      <FormHelperText classes={{ root: classes.helperTextRoot }} id={name}>{errors[name]}</FormHelperText>
    </FormControl>
  );
};

export default PasswordFieldUI;
