import styled from 'styled-components';
import { sizes } from '../../../styles/variables';

const BaseWrapper = styled.div`
  height: 100%;
  position: relative;
`;

export const FloatingWrapper = styled(BaseWrapper)``;
export const PopoverWrapper = styled(BaseWrapper)``;
export const PopupWrapper = styled(BaseWrapper)``;

const BaseFloatingElement = styled.div`
  width: 100%;
  min-width: 20vw;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0 .75em;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.floatingBoxShadow};
  z-index: 10;

  @media (max-width: ${sizes.xsMax}) {
    min-width: 50vw;
    left: auto;
    right: 0;
  }
`;

export const Popover = styled(BaseFloatingElement)``;
export const Popup = styled(BaseFloatingElement)``;

const BaseFloatingElementHeader = styled.p`
  font-weight: ${(props) => props.theme.font.weight.bolder};
  margin-top: 10px;
  margin-bottom: 6px;
`;

export const PopoverHeader = styled(BaseFloatingElementHeader)``;
export const PopupHeader = styled(BaseFloatingElementHeader)``;

const BaseFloatingElementContent = styled.div`
  color: ${(props) => props.theme.colors.gray800};
  margin-bottom: 12px;
`;

export const PopoverContent = styled(BaseFloatingElementContent)``;
export const PopupContent = styled(BaseFloatingElementContent)``;
