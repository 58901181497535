import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParam, StringParam } from 'use-query-params';
import { navigate } from 'gatsby';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Box, Typography } from '@material-ui/core';

import { IS_PATIENT_REGISTERED } from '../../graphql/queries';
import { SIGN_NEW_USER_PATIENT_UUID } from '../../graphql/mutations';
import Loading from '../Common/Loading';
import { InnerSpanHTML } from '../Common/InnerHTML';
import { AlertUI } from '../../componentsUI/Alert';
import useStyles from './styled/RegisterMakeStyles';

export const RegisterConfirmForm = ({ email, userUuid, loading, hospital }) => {
  const { t } = useTranslation();
  const [serverError, setServerError] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [patientUuid] = useQueryParam('patientUuid', StringParam);
  const [signature] = useQueryParam('signature', StringParam);
  const styles = useStyles();

  const { error, data } = useQuery(IS_PATIENT_REGISTERED, { variables: { patientUuid, userUuid } });
  const warning = error && error.graphQLErrors && error.graphQLErrors[0].message;

  if (warning) {
    return <AlertUI title="Error" severity="warning">{warning}</AlertUI>;
  }

  if (data && data.patientIsRegistered) {
    navigate('/dashboard');
  }

  const redirectToPatientPlatform = () => {
    window.location.href = `${process.env.GATSBY_APP_TELEMEDICINE_URL}/dashboard/`;
  };

  const [updateUserPatientUuid] = useMutation(SIGN_NEW_USER_PATIENT_UUID, {
    onCompleted: () => {
      setDisabledSubmit(false);
      redirectToPatientPlatform();
    },
  });

  const handleUpdateUserPatientUuid = async (uuid) => {
    setServerError(false);
    setDisabledSubmit(true);
    try {
      await updateUserPatientUuid({
        variables: {
          patientUuid,
          userUuid: uuid,
          signature,
          email,
        },
      });
    } catch (errors) {
      if (errors.graphQLErrors && errors.graphQLErrors.length) {
        setDisabledSubmit(false);
        setServerError(errors.graphQLErrors[0].message);
      }
    }
  };

  const DefaultContent = () => (
    <>
      <Typography variant="h5">
        {t('new.patient.user.registration')}
      </Typography>
      <br />
      <br />
      <Typography>
        <InnerSpanHTML content={t('new.patient.user.registration.info', { email })} />
      </Typography>
      <br />
      <Typography>{t('new.patient.user.registration.confirm')}</Typography>
    </>
  );

  const HospitalContent = ({ entity }) => (
    <>
      <Typography variant="h5">
        <strong>{entity.name}</strong>
        <br />
        {t('new.patient.user.registration')}
      </Typography>
      <br />
      <br />
      <Typography>
        <InnerSpanHTML content={t('new.patient.user.registration.info', { email })} />
      </Typography>
      <br />
      <Typography>
        <InnerSpanHTML content={t('new.patient.user.registration.confirm.hosp', { hospital: entity })} />
      </Typography>
    </>
  );

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Box className="mt-5 mb-4 text-center">
        {!hospital && <DefaultContent />}
        {!!hospital && <HospitalContent entity={hospital} />}
      </Box>
      {serverError && <AlertUI title="Error" severity="error">{serverError}</AlertUI>}
      {disabledSubmit && <Box><Loading /></Box>}
      <Box className="mt-4">
        <Button
          disabled={loading || disabledSubmit}
          type="button"
          variant="contained"
          color="primary"
          size="large"
          className={styles.submit}
          onClick={() => handleUpdateUserPatientUuid(userUuid)}
        >
          {t('confirm')}
        </Button>
      </Box>
    </Box>
  );
};
