import React, { useState } from 'react';

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import { useQuery } from '@apollo/react-hooks';
import { Box, Paper } from '@material-ui/core';
import { useQueryParam, StringParam } from 'use-query-params';

import { FIND_USER_BY_EMAIL, GET_MEMBER_HOSPITAL, GET_PATIENT_HOSPITAL } from '../../graphql/queries';
import logo from '../../images/logo.svg';
import Loading from '../Common/Loading';
import { isEmpty } from '../../utils/ObjectUtils';
import { emailVerifyUtils } from '../../utils/emailVerifiyUtils';
import useStyles from './styled/RegisterMakeStyles';
import { RegisterConfirmForm } from './RegisterConfirmForm';
import { RegisterNewUserForm } from './RegisterNewUserForm';
import { networkErrorParse } from '../../utils/ErrorGraphQLUtils';
import { getLocaleDefault, LocaleList } from '../../utils/LocaleUtils';
import { AlertWrapperUI, GraphQLAlertUI } from '../../componentsUI/Alert';

export const RegisterView = () => {
  const { t } = useTranslation();
  const [updatedLanguage, setUpdatedLanguage] = useState(false);
  const [email] = useQueryParam('email', StringParam);
  const [lang] = useQueryParam('language', StringParam);
  const [patientUuid] = useQueryParam('patientUuid', StringParam);
  const [hospitalUuid] = useQueryParam('hospitalUuid', StringParam);
  const styles = useStyles();

  const language = lang && lang.toLowerCase();

  const { loading, data } = patientUuid
    ? useQuery(GET_PATIENT_HOSPITAL, { variables: { patientUuid } })
    : useQuery(GET_MEMBER_HOSPITAL, { variables: { hospitalUuid } });

  const hospital = data && (data.patientHospital || data.memberHospital);

  if (language && LocaleList.some((i) => i.value === language) && !updatedLanguage) {
    i18n.changeLanguage(language.toLowerCase()).then();
    setUpdatedLanguage(true);
  }

  if (!language && !updatedLanguage) {
    i18n.changeLanguage(getLocaleDefault()).then();
    setUpdatedLanguage(true);
  }

  const getUserByEmail = (userEmail) => {
    if (!userEmail) {
      return null;
    }
    if (userEmail === '') {
      return null;
    }
    if (!emailVerifyUtils(userEmail)) {
      return null;
    }
    return useQuery(FIND_USER_BY_EMAIL, { variables: { email: userEmail } });
  };

  const user = getUserByEmail(email);

  const UserError = ({ error }) => {
    const message = networkErrorParse(error);
    return (
      <Box className={styles.container}>
        <AlertWrapperUI>
          <GraphQLAlertUI severity="error" title="Error" register>{t(message)}</GraphQLAlertUI>
        </AlertWrapperUI>
      </Box>
    );
  };

  if (user && user.loading) return <Loading />;
  if (user && user.error) return <UserError error={user.error} />;
  if (user && isEmpty(user.data)) return <Loading />;
  if (user && user.data && user.data.userByEmail && user.data.userByEmail.uuid && hospitalUuid) navigate('/dashboard');

  return (
    <Box className={styles.container}>
      <Paper elevation={4} className={styles.paper}>
        <Box>
          <img className={styles.image} src={logo} alt="logo" width="300" />
          {user && user.data && user.data.userByEmail
            ? <RegisterConfirmForm loading={loading} hospital={hospital} email={email} userUuid={user.data.userByEmail.uuid} />
            : <RegisterNewUserForm loading={loading} hospital={hospital} email={email} />}
        </Box>
      </Paper>
    </Box>
  );
};
