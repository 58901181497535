export const TermsConditionsEn = `
WARNING — &nbsp; **PLEASE READ THE TERMS OF USE OF THE PLATFORM CAREFULLY BEFORE REGISTERING AND MAKING USE OF THE PLATFORM. BY 
REGISTERING YOU ACCEPT THE TERMS. IF YOU DO NOT AGREE WITH ANY OF THE TERMS AND CONDITIONS OF USE, WE PLEASE DO NOT 
REGISTER, AND IN ANY CASE, DO NOT USE THE PLATFORM IN ANY WAY, OR INSTALL THE SOFTWARE OR ANY OTHER COMPONENT AND/OR 
ELEMENT OF THE SAME. REGISTRATION AND USE OF THE PLATFORM IN ANY WAY, MEANS THAT YOU KNOW AND ACCEPT THE TERMS AND 
CONDITIONS OF USE OF THIS PLATFORM, BEING BOUND BY THEMSELVES**.

**LICENSE FOR USE OF SERVICE FOR SUBSCRIBERS**

**ALMA IT SYSTEMS, S.L.** (hereinafter “**ALMA IT SYSTEMS**”), with address in Carrer del Mestre Dalmau 19, Barcelona, 
Spain and web site www.alma-medical.com, as owner and holder of all existing rights on the Platform that is the subject 
of this License, grants you authorization to use it, under the following terms and conditions:

#### 1. Definitions

For the purposes of this License, it is understood as:

  - **Service**: The Platform is an integrated medical content service that enables the diagnosis of specific 
pathologies or other purposes, through the interpretation and exchange of medical images and clinical data. As 
component parts of the Platform it is included the web pages, servers, programs and any other components and/or 
elements that make it up, as well as (i) technical information, installation manuals, instructions for use or any 
other documentation related to the Service; (ii) the images, photographs, sounds, viewer, or other own content files; 
and (iii) any versions, updates, modifications and / or improvements of the Service. 
  - **Subscriber**: The person, organization, hospital, or company that registers and uses the Platform. Each Subscriber 
has their own account, which is associated with an email address and includes the set of information regarding the 
Subscriber and the corresponding associated users. 
  - **User**: The individual who, under the coordination, authorization, and direction of the Subscriber, can use the 
Platform for its intended purposes. 
  - **Platform Website**: The platform's registration is located at the URL ${process.env.GATSBY_KEYCLOAK_URL}. The 
content and access to the different functionalities are hosted at the URL ${process.env.GATSBY_APP_CLOUD_URL}. 
  - **Contents**: Any files and/or documents managed and/or stored or uploaded by Users to the Platform, in any of the 
currently supported formats or those that may be supported in the future (JPEG, PDF, DICOM standard...) that may include 
medical data of third parties.


#### 2. License of use

(SUBSCRIBER) and all users related to such subscription (USERS), a non-exclusive License of use of the Platform, as an 
ancillary provision inherent to the existence of an active subscription. This License will be considered granted to the 
Subscriber, once they manifest acceptance of its terms and conditions at the time of registering on the Platform, and will 
be valid for as long as they maintain their subscription active. The License of Use on the Platform will be initially 
activated once the payment of the first installment of the chosen modality by the Subscriber at the time of registration 
is made, and will remain active as long as the corresponding installments are paid with the determined periodicity. If 
once registered, the Subscriber does not activate, or does not maintain an active subscription, **ALMA IT SYSTEMS** may 
terminate this License of use and proceed to delete the account of the Subscriber. At all times, on the Platform's website, 
the different subscription modalities available and their prices, as well as possible existing promotions, can be consulted.

The Platform's license is exclusive and non-transferable, and authorizes the Subscriber and the Users associated with it, 
identified in their account, to use the Platform according to the contracted subscription modality, and subject to the 
terms and conditions established therein. The Subscriber is obliged and undertakes to transfer such terms and conditions 
to any Users associated with their account, as well as to obtain their express acceptance prior to granting them access 
to the Platform. The Subscriber will be responsible in case any of the Users associated with their account have not given 
their prior and proper acceptance to the terms and conditions of this License, and in any case, will hold **ALMA IT SYSTEMS** 
harmless from any claims that may arise from such non-compliance.

This License includes authorization for the Subscriber to make acceptable use of the medical content integrated into the 
Platform, including the viewer, or any present or future tool, installation, loading, transfer, copying, and/or 
reproduction of the same on any computer or device, only to the extent necessary for the normal operation of the Platform, 
and in a manner that is in accordance with its own purpose and specifications. The authorization for use extends to the 
Users associated with the Subscriber's account, on the terms indicated above, who must also make acceptable use of the 
Platform under the responsibility of the Subscriber.

Any other form of use of the Platform that has not been expressly included in the purpose of this License of use is 
expressly prohibited, and in particular, any exploitation of the Platform and/or its contents and/or elements that does 
not limit itself to the acceptable use provided here, as well as the granting of any kind of sub-licenses to third 
parties, not even on a free-of-charge basis. In the event of detecting any use of the Platform that does not conform to 
the authorized acceptable use, and/or that constitutes an abuse of the use of the Platform, **ALMA IT SYSTEMS** shall be 
entitled to interrupt and/or block any access to it by the Subscriber and/or the Users associated with their account.

In addition to the expiration of the subscription, the License may also be terminated automatically by **ALMA IT SYSTEMS** 
in the event of non-compliance with any of its terms by the Subscriber and/or any of the Users associated with their 
account. When the License is no longer valid, for whatever reason, the Subscriber will have 5 business days from 
receiving the notice to retrieve the data uploaded to the Platform. After this period, all data and content of the 
Subscriber whose License has expired will be deleted, unless **ALMA IT SYSTEMS** is required to keep the data uploaded by 
the Subscriber for the legal period, after which it will be deleted.

The terms of use described here may be modified according to new functionalities and/or the release of new versions. In 
such case, the new license terms will be notified to the Subscriber and/or Users for their acceptance.

#### 3. Reservation of intellectual and industrial property rights

The Platform is owned by **ALMA IT SYSTEMS**, which is the sole and exclusive owner of any existing rights over it and its 
contents and images that make it up, and in particular, of the corresponding intellectual and industrial property rights, 
including, merely by way of example and not limited to, the exclusive rights over the software, source code, designs, 
interfaces, patents, trademarks, logos, and any other existing rights that are protected by national and international 
legislation, and with respect to which **ALMA IT SYSTEMS** expressly reserves.

In addition to the contents and components such as the viewer and other proprietary elements, the operation of the 
Platform involves the use of data provided by third parties, whose rights belong to their respective owners, which are 
also subject to the protection that particularly corresponds to them in accordance with national and international 
legislation.

This License does not constitute a sale or any form of assignment of rights over the Platform, nor over its contents, 
components, and data stored by third parties involved in the use of the Platform. Except for the right to use under the 
terms previously indicated, this License does not grant the Subscriber, nor any of the Users associated with their 
account, any other rights over the Platform, its viewer, components, and other elements, nor over any resources and data 
from third parties.

#### 4. Privacy Policy

The Platform contains personal data uploaded by the Subscriber and/or User of the same, which are managed by **ALMA IT 
SYSTEMS**. The Subscriber and/or User who uploads personal data declares that they are expressly authorized by the 
affected party. The incorporation of personal data into the Platform by the Subscriber and/or User is prohibited if not 
expressly authorized.  
Thus, the content managed and stored through the use of the Service by Subscribers and/or Users may include personal 
data of third parties, particularly sensitive medical data.  
Subscribers and/or Users accept that the treatment of the Content will be carried out under their sole responsibility, 
and as controllers of any such treatments, they declare to have the necessary authorizations from the interested 
parties, and considering the nature of the data that may be included in the Content, they undertake to strictly comply 
with the applicable regulations regarding the processing of personal data, as well as to hold **ALMA IT SYSTEMS** harmless 
in the event of any breach on their part.  
Through this License, **ALMA IT SYSTEMS** is entrusted with the processing of the Content solely and exclusively for the 
purpose of providing the Service, limiting its liability, as the data processor, to ensuring the security and compliance 
with the regulations on data protection in the management and storage of the Content that is the subject of the assignment, 
both on its part and on the part of any providers involved in the provision of the Service. **ALMA IT SYSTEMS** will not process 
the personal data that may be contained in the medical Content beyond what is necessary for the provision of the Service.

All personal data collected through the registration of the Subscriber and/or Users during the use of the Platform, will 
be incorporated into a file owned by **ALMA IT SYSTEMS**, which will be responsible for the file, for the purpose of using 
them for the management and provision of the Service, as well as for sending any commercial, promotional and/or 
advertising communications related to its products and/or services.  
The Subscriber and/or Users expressly accept the processing of their personal data for these purposes, as well 
as expressly accepting the processing of the data of third parties that they have incorporated or uploaded to 
the Platform, without prejudice to their right to exercise the rights that legally correspond to them, in 
particular, the rights of access, rectification, cancellation, and opposition (ARCO rights), by sending an email 
to the address rgpd@alma3d.com, properly identifying themselves and clearly indicating the purpose of their request.  
In any case, since the effective provision of the Service requires having the aforementioned personal data of the 
Subscriber and/or User, the order to cancel the data will result in the cancellation of the subscription to the Service 
and the deletion of their account, without prejudice to what is mentioned in point 2, section 5.

The personal data provided by the Subscriber of the Users associated with their account will also be included in said 
file owned by **ALMA IT SYSTEMS**, and may be used for the same purposes. The Subscriber is obliged and committed to obtain 
the prior and express authorization of the Users for the processing of their data for the indicated purposes, and will 
be responsible in the event that they do not do so properly, holding **ALMA IT SYSTEMS** harmless from any claims that may 
arise from such non-compliance.  
In any case, access to the Platform by any of the Users associated with the Subscriber's 
account implies their express acceptance for the processing of their personal data for the indicated purposes. **ALMA IT 
SYSTEMS** will retain the data of the Users until the Subscriber's account to which they are associated is deleted, or 
until the Subscriber, or the person authorized by the Subscriber for this purpose, removes a User from the list 
associated with their account.

In the event that the management of the collection of the corresponding fees for the acquired subscription will be 
carried out by a third party to whom **ALMA IT SYSTEMS** entrusts the provision of said collection service, they will be 
solely responsible to the Subscriber, both for the provision of the service itself and for the processing of the 
personal data necessary for it, including the corresponding bank details. This collection service will include access 
to a payment gateway to which the Service will connect during the registration process for the collection of the 
corresponding fee.  
**ALMA IT SYSTEMS** will not store or have access at any time to the banking or other personal data 
provided by the Subscriber at the time of making the payment through such service provided by a third party, beyond 
receiving confirmation of the payment made by the Subscriber and the corresponding amount. To learn about the details 
of the processing of such personal data, the Subscriber should consult the information provided by the service provider, 
who will be duly identified at the time of acquiring the subscription.

The personal data provided by any Subscribers and Users of the Service must be accurate, truthful, and up to date, and 
it is their sole responsibility to keep them updated at all times, assuming the damages that may arise from not doing 
so.  
Likewise, all Subscribers and Users of the Service will be responsible for the proper custody of their respective 
identifiers, passwords, or any other data used for the identification and/or access to the Service, assuming all 
responsibility that may arise from their misuse, transfer, disclosure, or loss.  
Likewise, the personal medical data that the Subscriber incorporates into the Platform must also be accurate and truthful.

#### 5. Subscriber's Obligations

The Subscriber expressly assumes the following obligations, both on his own behalf and on behalf of all Users associated 
with his account:

 - Use the Service in full compliance with the terms and conditions established in this License.
 - Take all measures that are reasonably within his reach to prevent any unauthorized use of the Service.

In addition, in particular, the Subscriber, both on his own behalf and on behalf of all Users associated with his 
account, undertakes not to perform, either by himself or through a third party, any of the following acts:

 - Modify, adapt, translate, disassemble, decompile, or otherwise manipulate the Service or any of its contents or components and/or elements, whether owned by the user or by third parties.
 - Copy or reproduce the Service or any of its components and/or elements, whether owned by the user or by third parties, except to the extent strictly necessary for its normal execution, in accordance with its own purpose and specifications.
 - Assign, license, lease, rent, transfer, or otherwise exploit the Service, in whole or in part, directly or through third parties, not even temporarily.
 - Disclose, disseminate, or otherwise communicate the code and implementation of the Service or any of its components and/or elements, whether owned by the user or by third parties, expressly acknowledging that they are subject to exclusive rights and are subject to due confidentiality.

#### 6. Technical Support

  1. **ALMA IT SYSTEMS** will provide technical support to the Subscriber regarding the use of the Service in a non-face-to-face manner (via telephone assistance or email). However, technical support will only be provided to a Subscriber if they have an active subscription at the time of the request.
  1. Any inquiry, doubt, or request for information related to this License or the Service that is the subject of it should be directed to the technical support department or the designated contact person on the Service's website.

#### 7. Exclusion of warranties

  1. **ALMA IT SYSTEMS** guarantees the Subscriber that the Service/Platform conforms to the specifications and functionality described in the documentation included therein. However, this warranty does not apply in cases where the Service operates with non-substantial variations from what is indicated in the documentation.
  1. Except for the warranty indicated in the previous paragraph, and in the terms thereof, **ALMA IT SYSTEMS** excludes any other warranties regarding the Service, whether express, implied, or statutory, including, in this exclusion, merely for illustrative purposes and not limited to, warranties of quality, satisfaction of needs, suitability for a particular purpose, accuracy, uninterrupted and error-free operation, and in general, use and enjoyment, which are not guaranteed, without prejudice to **ALMA IT SYSTEMS**' commitment to dedicating its best efforts, professionalism, and diligence to improving the Service.
  1. Likewise, the Subscriber acknowledges and expressly accepts that **ALMA IT SYSTEMS** cannot control the conditions under which the Users associated with their account use the Platform, so their use will be at their sole risk and responsibility, assuming full responsibility for any defect in the quality, availability, scope, effectiveness, and/or, in general, the provision of the Service, resulting from the respective conditions of use thereof.
  1. To make any warranty claim, the Subscriber must contact **ALMA IT SYSTEMS** within a maximum period of fifteen (15) days from the detection of the defect in the Service subject to the claim, detailing the reason for the claim and providing all relevant information and/or documentation. In any case, if **ALMA IT SYSTEMS** deems the claim appropriate, it will be obliged, at its sole discretion, to rectify the corresponding Service, or to fully or partially refund the subscription amount paid by the Subscriber.

#### 8. Limitation of Liability

  1. **ALMA IT SYSTEMS** shall not be liable, under any circumstances or in any way, and the Subscriber expressly accepts 
  this, for any damages and/or losses that may occur in relation to the use of the Service by the Subscriber or the 
  Users, even in the event of defective use or inability to access the Platform.  
  Such limitation of liability extends to 
  any damages and/or losses, whether they are material and/or personal, in the form of loss of profit or consequential 
  damage, and/or whether they are direct, indirect, special, or incidental damages, including in the exclusion of 
  liability, merely by way of example and not limitation, damages of a punitive nature —and in particular, for injuries, 
  death, etc.—, economic losses —for loss of profits, loss of data, interruption of business activity, or any other type 
  of commercial, economic, or business-related damages and losses—, and damages and losses arising from any claims made 
  by third parties, regardless of how the damages and losses occurred and the basis on which liability is sought to be 
  attributed. 
  1. The Platform is intended for the individual use of healthcare professionals or hospital staff, or for individual 
  persons acting under their own responsibility. Subscribers and/or Users will use the Service under their own and 
  exclusive medical responsibility.  
  no case will **ALMA IT SYSTEMS** be responsible, with the Subscriber and/or Users 
  expressly waiving any claims related to damages suffered by the Subscriber, Users, and/or third parties due to misuse 
  or abuse of the Service—including, in particular but not limited to, possible defects in the quality of images and 
  medical content managed and stored by the Subscriber and/or Users, any medical diagnostic results, etc.—or caused by 
  failures or defects in equipment, devices, infrastructures, resources, and/or any third-party services—including, but 
  not limited to, server failures, Internet services, and data communication failures, etc.—as well as damages that may 
  result from the introduction of viruses, worms, or any other type of harmful malware or computer element, it is the 
  responsibility of the Subscriber and Users to have or implement the appropriate tools for their detection, protection, 
  and disinfection. 
  1. The aforementioned exclusions of liability shall apply to the extent permitted by applicable law, and in any case, 
  if liability on the part of **ALMA IT SYSTEMS** is established, it shall be limited in total and at most to the amount of 
  the subscription fee for the last annual payment made by the Subscriber.

#### 9. Other provisions

  1. The terms and conditions of this **License** may only be modified upon the express acceptance of **ALMA IT SYSTEMS**.
  1. In the event of any discrepancies in the interpretation of the terms of this **License** in different languages, the 
  Spanish version shall prevail over the others.

### Jurisdiction and applicable law

  - The Courts of Barcelona shall have exclusive jurisdiction to hear and resolve any disputes related to this **License**, 
  with all parties agreeing to submit to them, expressly waiving any other jurisdiction that may correspond to them.
  - This **License** shall be governed and interpreted in accordance with Spanish laws.

`;
