import React from 'react';
import '../../i18n';

import { EmptyPageModern } from '../../components/Common/styled/EmptyPage';
import { RegisterView } from '../../components/Register/RegisterView';

import Seo from '../../components/Seo';
import { AppContainer } from '../../components/Common/styled/AppContainer';
import GlobalStyle from '../../themes/GlobalStyle';
import GlobalThemeStyles from '../../themes/GlobalThemeStyles';

export default () => (
  <AppContainer>
    <Seo />
    <GlobalStyle />
    <GlobalThemeStyles />
    <EmptyPageModern>
      <RegisterView />
    </EmptyPageModern>
  </AppContainer>
);
