export const TermsConditionsEs = `
ADVERTENCIA — &nbsp; **LE ROGAMOS QUE LEA DETENIDAMENTE LAS CONDICIONES DE USO DE LA PLATAFORMA ANTES DE REGISTRARSE Y 
HACER USO DE LA MISMA.   AL REGISTRARSE USTED ACEPTA LAS CONDICIONES.  SI USTED NO ESTÁ DE ACUERDO CON ALGUNO DE LOS 
TÉRMINOS Y CONDICIONES DE USO, LE ROGAMOS QUE NO SE REGISTRE, Y EN TODO CASO, QUE NO HAGA USO DE LA PLATAFORMA EN FORMA 
ALGUNA, NI INSTALE EL SOFTWARE O CUALQUIER OTRO COMPONENTE Y/O ELEMENTO DEL MISMO.  EL REGISTRO Y EL USO DE LA 
PLATAFORMA DE CUALQUIER FORMA, SIGNIFICAN QUE USTED CONOCE Y ACEPTA LOS TÉRMINOS Y CONDICIONES DE USO DE ESTA PLATAFORMA, 
QUEDANDO VINCULADO POR LOS MISMOS.**.

**LICENCIA DE USO DEL SERVICIO PARA SUSCRIPTORES**

**ALMA IT SYSTEMS, S.L.** (en adelante “**ALMA IT SYSTEMS**”), con domicilio en Barcelona, calle Mestre Dalmau, 19 y con 
página web **www.alma-medical.com**, como propietario y titular de todos los derechos existentes sobre la Plataforma de 
que es objeto de esta Licencia, le concede autorización para hacer uso de la misma, bajo los siguientes términos y 
condiciones:

#### 1. Definiciones

A los efectos de la presente Licencia, se entiende por:

  - **Servicio**:
La Plataforma es un servicio integrado de contenidos médicos que permite el diagnóstico de unas patologías concretas u 
otros fines, mediante la interpretación y el intercambio de imágenes médicas y datos clínicos.  
Como partes componentes de la Plataforma se incluyen las páginas webs, servidores, programas y cualesquiera otros 
componentes y/o elementos que lo integran, así como también (i) la información técnica, manuales de instalación, 
instrucciones de uso o cualquier otra documentación relativa al Servicio; (ii) las imágenes, fotografías, sonidos, visor, 
u otros archivos de contenidos propios; y (iii) cualesquiera versiones, actualizaciones, modificaciones y/o mejoras del 
Servicio. 
  - **Suscriptor**: La persona, organización, hospital o empresa que se registra y hace uso de la Plataforma. Cada 
Suscriptor tiene su propia cuenta, la cual tiene asociado una dirección de correo electrónico e incluye el conjunto de 
información referente al mismo y el correspondiente a los usuarios asociados.
  - **Usuario**: La persona física que bajo la coordinación, autorización y dirección del Suscriptor puede utilizar la 
Plataforma para las finalidades que le son propias.
  - **Sitio Web de la Plataforma**: El registro a la plataforma está ubicado en la URL ${process.env.GATSBY_KEYCLOAK_URL}. 
El contenido y el acceso a las diferentes funcionalidades están alojadas en la URL ${process.env.GATSBY_APP_CLOUD_URL}.
  - **Contenidos**: Cualesquiera archivos y/o documentos gestionados y/o almacenados o subidos por los Usuarios a la 
Plataforma, en cualquiera de los formatos admitidos actualmente o que puedan admitirse en el futuro (JPEG, PDF, 
estándar DICOM…) que pueden incluir datos de carácter médico de terceras personas.  


#### 2. Licencia de Uso

(SUSCRIPTOR) y a todos los usuarios relacionados con dicha suscripción (USUARIOS), una Licencia de uso no exclusivo de 
la Plataforma, como prestación accesoria inherente a la existencia de una suscripción activa Esta Licencia se considerará 
concedida al Suscriptor, una vez éste manifieste la aceptación de sus términos y condiciones en el momento de registrarse 
en la Plataforma, y estará vigente durante el tiempo en el que mantenga activa su suscripción al mismo. La Licencia de 
Uso en la Plataforma se activará inicialmente una vez se realice el pago de la primera cuota de la modalidad elegida por 
el Suscriptor en el momento de registrarse, y se mantendrá activa siempre que se abonen las cuotas correspondientes con 
la periodicidad determinada. Si una vez registrado, el Suscriptor no activa, o no mantiene activa una suscripción, **ALMA 
IT SYSTEMS** podrá resolver la presente Licencia de uso y proceder a la eliminación de la cuenta del Suscriptor. En todo 
momento, en el Sitio Web de la Plataforma se podrán consultar las distintas modalidades de suscripción disponibles y su 
precio así como las posibles promociones existentes.

La Licencia de uso de la Plataforma es exclusiva e intransferible, y faculta al Suscriptor y a los Usuarios asociados al 
mismo, identificados en su cuenta, a hacer uso de la Plataforma, de acuerdo con la modalidad de suscripción contratada, 
y con plena sujeción a los términos y condiciones establecidos en la misma. El Suscriptor queda obligado y se compromete 
a trasladar dichos términos y condiciones a cualesquiera Usuarios asociados a su cuenta, así como a obtener su expresa 
aceptación de los mismos de forma previa a darles acceso a la Plataforma. El Suscriptor será responsable en el caso que 
cualquiera de los Usuarios asociados a su cuenta no haya dado su aceptación previa y en la forma debida a los términos y 
condiciones de la presente Licencia, y en todo caso, mantendrá indemne a **ALMA IT SYSTEMS** de cualesquiera reclamaciones 
que pudieran derivarse de tal incumplimiento.

Esta Licencia incluye la autorización al Suscriptor para hacer un uso aceptable de los contenidos médicos integrados en 
la Plataforma, incluyendo el visor, o cualquier herramienta presente o futura, la instalación, carga, transferencia, 
copia y/o reproducción de los mismos en cualquier equipo o dispositivo informático, únicamente en cuanto sea necesario 
para el normal funcionamiento de la Plataforma, y de manera que sea conforme a su propia finalidad y especificaciones. 
La autorización de uso alcanza a los Usuarios asociados a la cuenta del Suscriptor, en los términos antes indicados, 
quienes también deberán realizar un uso aceptable la Plataforma bajo la responsabilidad del Suscriptor.

Queda expresamente prohibida cualquier otra forma de utilización de la Plataforma que no haya sido expresamente recogida 
en el objeto de esta Licencia de uso, y en particular, cualquier explotación de la Plataforma y/o de sus contenidos y/o 
elementos que no se limite al uso aceptable aquí previsto, así como la concesión a terceros de cualquier clase de 
sub-licencias, ni siquiera con carácter gratuito. En el caso de detectar cualquier uso de la Plataforma que no se ajuste 
al uso aceptable autorizado, y/o que suponga un abuso del uso de la Plataforma, **ALMA IT SYSTEMS** quedará facultado para 
interrumpir y/o bloquear cualquier acceso a la misma por parte del Suscriptor y/o de los Usuarios asociados a su cuenta.

Además de por la finalización de la vigencia de la suscripción, la Licencia también podrá darse por finalizada 
automáticamente por parte de **ALMA IT SYSTEMS**, en caso de incumplimiento de cualquiera de los términos de la misma por el 
Suscriptor y/o cualquiera de los Usuarios asociados a su cuenta. Cuando la Licencia deje de estar en vigor, cualquiera 
que sea la causa, el Suscriptor dispondrá de 5 días hábiles desde que reciba el aviso para recuperar los datos subidos 
por el mismo a la Plataforma. Transcurrido ese plazo todos los datos y contenidos del Suscriptor cuya Licencia ha dejado 
de estar en vigor serán eliminados, salvo que **ALMA IT SYSTEMS** viniera obligada a guardar los datos subidos por el 
Suscriptor, durante el plazo legal, transcurrido el cual, se eliminarán.

Las condiciones de uso aquí descritas se podrán modificar de acuerdo con nuevas funcionalidades y/o el lanzamiento de 
nuevas versiones. En tal caso, se notificará las nuevas condiciones de la licencia de uso al Suscriptor y/o los Usuarios 
para su aceptación.

#### 3. Reserva de derechos de propiedad intelectual e industrial

La Plataforma es propiedad de **ALMA IT SYSTEMS**, que es titular único y exclusivo de cualesquiera derechos existentes 
sobre la misma y sobre sus contenidos e imágenes que lo integran, y en particular, de los derechos de propiedad 
intelectual e industrial correspondientes, incluyendo, con carácter meramente enunciativo y no limitativo, los derechos 
exclusivos existentes sobre el software, código fuente, diseños, interfaces, patentes, marcas, logotipos y cualesquiera 
otros derechos existentes que están protegidos por la legislación nacional e internacional de aplicación, y respecto a 
los que **ALMA IT SYSTEMS** hace una expresa reserva.

Además de los contenidos, y componentes como el visor y otros elementos propios, el funcionamiento de la Plataforma 
supone la utilización de datos facilitados por terceros, cuyos derechos pertenecen a sus respectivos titulares, que son 
igualmente objeto de la protección que particularmente les corresponda con arreglo a la legislación nacional e 
internacional de aplicación.

Esta Licencia no constituye una venta ni cualquier forma de cesión de derechos sobre la Plataforma, ni sobre sus 
contenidos, componentes y datos almacenados de terceros involucrados en el uso de la Plataforma. Salvo el derecho de uso 
en los términos anteriormente indicados, esta Licencia no otorga al Suscriptor, ni tampoco a cualquiera de los Usuarios 
asociados a su cuenta, ningún otro derecho sobre la Plataforma, su visor, componentes y otros elementos, ni sobre 
cualesquiera recursos y datos de terceros.

#### 4. Política de privacidad

La Plataforma contiene datos personales subidos por el Suscriptor y/o Usuario de la misma que son gestionados por **ALMA 
IT SYSTEMS**. El Suscriptor y/ o Usuario que suba datos personales declara que está autorizado expresamente por el 
afectado. Queda prohibida la incorporación de datos personales a la Plataforma por parte del Suscriptor y/o Usuario si 
no está expresamente autorizado.  
Así, los contenidos gestionados y almacenados a través del uso del Servicio por los Suscriptores y/o los Usuarios pueden 
incluir datos personales de terceras personas, y en particular, datos médicos de carácter sensible. 
Los Suscriptores y/o 
los Usuarios aceptan que el tratamiento de los Contenidos se realizará bajo su exclusiva responsabilidad, y como 
responsables de cualesquiera de dichos tratamientos, declaran disponer de las autorizaciones necesarias para ello de los 
interesados, y atendida la naturaleza de los datos que pueden incluir los Contenidos, se obligan y comprometen al 
estricto cumplimiento de la normativa aplicable en materia de tratamiento de datos personales, así como a mantener 
indemne a **ALMA IT SYSTEMS** en el caso que se produzca cualquier incumplimiento por su parte.  
Mediante la presente 
Licencia, **ALMA IT SYSTEMS** recibe el encargo del tratamiento de los Contenidos con la única y exclusiva finalidad de 
llevar a cabo la prestación del Servicio, limitando su responsabilidad, como tal encargado del tratamiento, a velar por 
la seguridad y el cumplimiento de la normativa en materia de protección de datos en la gestión y almacenamiento de los 
Contenidos que es objeto del encargo, tanto por su parte como por parte de cualesquiera proveedores que intervengan en 
dicha prestación del Servicio. **ALMA IT SYSTEMS** no tratará los datos personales que pudieran contener los Contenidos 
médicos, más allá de lo necesario para la prestación del Servicio.

Todos los datos de carácter personal recogidos a través del registro del Suscriptor y/o Usuarios durante la propia 
utilización de la Plataforma, serán incorporados a un fichero de titularidad de **ALMA IT SYSTEMS**, que será el responsable 
del fichero, con la finalidad de utilizarlos para la gestión y prestación del Servicio, así como también para el envío 
de cualesquiera comunicaciones de carácter comercial, promocional y/o publicitario relacionadas con sus productos y/o 
servicios. El Suscriptor y/o Usuarios acepta expresamente el tratamiento de sus datos personales con dichas finalidades, 
así como que acepta expresamente el tratamiento de los datos de terceros que haya incorporado o subido a la Plataforma, 
sin perjuicio de que podrá ejercitar los derechos que legalmente le corresponden, y en particular los de acceso, 
rectificación, cancelación y oposición (derechos ARCO), mediante el envío de un correo electrónico a la dirección 
rgpd@alma3d.com, identificándose debidamente e indicando con claridad el objeto de su solicitud. En todo caso, dado que 
la efectiva prestación del Servicio requiere disponer de los referidos datos personales del Suscriptor y/o Usuario, la 
orden de cancelación de los datos conllevará la baja de la suscripción al Servicio y la eliminación de su cuenta, sin 
perjuicio de lo mencionado en el punto 2 apartado 5.

Los datos personales facilitados por el Suscriptor de los Usuarios asociados a su cuenta serán igualmente incorporados a 
dicho fichero de titularidad de **ALMA IT SYSTEMS**, y podrán ser utilizados con las mismas finalidades. El Suscriptor queda 
obligado y se compromete a obtener la previa y expresa autorización de los Usuarios para el tratamiento de sus datos con 
las finalidades indicadas, y será responsable en el caso que no lo haga en la forma debida, manteniendo indemne a **ALMA 
IT SYSTEMS** de cualesquiera reclamaciones que pudieran derivarse de tal incumplimiento. En todo caso, el acceso a la 
Plataforma por cualquiera de los Usuarios asociados a la cuenta del Suscriptor conlleva una aceptación expresa por su 
parte para el tratamiento de sus datos personales con las finalidades indicadas. **ALMA IT SYSTEMS** conservará los datos de 
los Usuarios hasta que se elimine la cuenta del Suscriptor a la que están asociados, o bien, hasta que el Suscriptor, o 
la persona autorizada por éste a tal efecto, elimine a un Usuario de la lista de los asociados a su cuenta.

En el caso de que la gestión del cobro de las cuotas correspondientes a la suscripción adquirida se realizará por parte 
de un tercero al que **ALMA IT SYSTEMS** encargue la prestación de dicho servicio de cobro, éste será el único responsable 
frente al Suscriptor, tanto de la propia prestación del mismo, como del tratamiento de los datos personales necesarios 
para ello, incluyendo los correspondientes datos bancarios. Dicho servicio de cobro incluirá el acceso a una pasarela de 
pago a la que el Servicio se conectará durante el procedimiento de registro para el cobro de la cuota correspondiente. 
**ALMA IT SYSTEMS** no almacenará ni tendrá acceso en ningún momento a los datos bancarios u otros datos personales 
facilitados por el Suscriptor en el momento de efectuar el pago a través de dicho servicio prestado por un tercero, más 
allá de recibir la confirmación del pago efectuado por el Suscriptor y del importe correspondiente. Para conocer los 
detalles del tratamiento de dichos datos personales, el Suscriptor deberá consultar la información ofrecida por el 
titular del servicio cobro, que será debidamente identificado en el momento de adquirir la suscripción.

Los datos personales facilitados por cualesquiera Suscriptores y Usuarios del Servicio deberán ser exactos, veraces y 
actuales, siendo responsabilidad exclusiva de los mismos mantenerlos actualizados en todo momento, asumiendo en caso 
contrario los perjuicios que de ello pudieran causarse. Asimismo, todos los Suscriptores y Usuarios del Servicio serán 
responsables de la debida custodia de sus respectivos identificadores, contraseñas o cualesquiera otros datos utilizados 
para la identificación y/o acceso al Servicio, asumiendo toda la responsabilidad que pudiera derivarse del uso indebido, 
cesión, revelación o extravío de los mismos.  
Igualmente los datos médicos personales que el Suscriptor incorpore a la Plataforma deberán ser también exactos y veraces.

#### 5. Obligaciones del Suscriptor

El Suscriptor asume expresamente las siguientes obligaciones, tanto en nombre propio como en nombre de todos los Usuarios 
asociados a su cuenta:

  - Utilizar el Servicio con plena conformidad a los términos y condiciones establecidos en esta Licencia.
  - Tomar todas las medidas que razonablemente se encuentren a su alcance para impedir cualesquiera usos no autorizados 
  del Servicio.

Además, en particular, el Suscriptor, tanto en nombre propio como en nombre de todos los Usuarios asociados a su cuenta, 
se obliga a no realizar, por sí o mediante un tercero, ninguno de los siguientes actos:

  - Modificar, adaptar, traducir, desmontar, descompilar o de cualquier otro modo manipular el Servicio o cualquiera de 
  sus contenidos o componentes y/o elementos, propios o de terceros.
  - Copiar o reproducir el Servicio o cualquiera de sus componentes y/o elementos, propios o de terceros, salvo en lo 
  que con carácter instrumental resulte estrictamente necesario para su normal ejecución, de acuerdo con su propia 
  finalidad y especificaciones.
  - Ceder, licenciar, alquilar, arrendar prestar, transferir o explotar de cualquier otro modo el Servicio, en todo o en 
  parte, ya sea directamente o a través de terceros, ni siquiera de forma temporal.
  - Revelar, difundir o comunicar de cualquier otro modo el código e implementación del Servicio o de cualquiera de sus 
  componentes y/o elementos, propios o de terceros, reconociendo expresamente que son objeto de derechos exclusivos, y 
  que están sometidos a la debida confidencialidad.

#### 6. Soporte Técnico

  1. **ALMA IT SYSTEMS** facilitará al Suscriptor soporte técnico relativo al uso del Servicio de forma no presencial 
  (mediante asistencia telefónica o vía e-mail). No obstante, sólo se facilitará soporte técnico a un Suscriptor siempre que en su cuenta disponga de una suscripción activa en el momento de solicitarlo.
  1. Toda consulta, duda o solicitud de información relativa a la presente Licencia o al Servicio que es objeto de la 
  misma deberá dirigirse al departamento de soporte técnico o a la persona de contacto indicada al efecto en el Sitio 
  Web del Servicio.

#### 7. Exclusión de garantías

  1. **ALMA IT SYSTEMS** garantiza al Suscriptor que el Servicio/Plataforma se adecua a las especificaciones y funcionalidad 
  descrita en la documentación incluida en la misma. No obstante, dicha garantía no se hace extensiva a los casos en los 
  que el funcionamiento del Servicio se produzca con variaciones no sustanciales respecto a lo indicado en la 
  documentación.
  1. Salvo la garantía indicada en el párrafo anterior, y en los términos de la misma, **ALMA IT SYSTEMS** excluye cualquier 
  otra garantía respecto al Servicio, sean expresas, implícitas o legales, incluyendo en dicha exclusión, con carácter 
  meramente enunciativo y no limitativo, las garantías de calidad, de satisfacción de sus necesidades, de idoneidad para 
  un fin determinado, de exactitud, de funcionamiento ininterrumpido y sin errores ni defectos, y en general de uso y 
  disfrute, que no se ven garantizados, sin perjuicio del compromiso de **ALMA IT SYSTEMS** en dedicar sus mejores esfuerzos, 
  profesionalidad y diligencia a la mejora del Servicio.
  1. Asimismo, el Suscriptor reconoce y acepta de forma expresa que **ALMA IT SYSTEMS** no puede tener control sobre las 
  condiciones bajo las cuales los Usuarios asociados a su cuenta usan la Plataforma, de manera que su uso se realizará 
  bajo su exclusivo riesgo y responsabilidad, asumiendo enteramente cualquier defecto generado sobre la calidad, 
  disponibilidad, alcance, efectividad y/o, en general, la propia prestación del Servicio, que venga derivado de las 
  respectivas condiciones de uso del mismo.
  1. Para efectuar cualquier reclamación de garantía, el Suscriptor deberá dirigirse a **ALMA IT SYSTEMS**, en un plazo 
  máximo de quince (15) días desde que se detecte el defecto del Servicio objeto de la misma, detallando el motivo de 
  su reclamación y aportando toda la información y/o documentación relativa a la misma. En todo caso, si **ALMA IT SYSTEMS** 
  considera procedente dicha reclamación, como máximo se verá obligado, y a su entera discreción, a la subsanación del 
  Servicio que corresponda, o bien al reembolso total o parcial del importe de la suscripción abonada por el Suscriptor.

#### 8. Limitación de Responsabilidad

  1. **ALMA IT SYSTEMS** no será responsable, en ningún caso ni de ningún modo, y así lo acepta expresamente el Suscriptor, 
  de cualesquiera daños y/o perjuicios que puedan producirse con relación al uso del Servicio por parte del Suscriptor 
  o de los Usuarios, incluso en el caso de producirse un uso defectuoso o la imposibilidad de acceso a la Plataforma. 
  Tal limitación de responsabilidad se hace extensiva a cualesquiera daños y/o perjuicios, ya sean daños materiales y/o 
  personales, en forma de lucro cesante o de daño emergente, y/o se trate de daños directos, indirectos, especiales o 
  incidentales, incluyendo en la exclusión de responsabilidad, con carácter meramente enunciativo y no limitativo, los 
  daños de carácter punitivo —y en particular, por lesiones, muerte, etc.—, los perjuicios de carácter económico —por 
  la pérdida de beneficios, la pérdida de datos, la interrupción de la actividad empresarial, o cualquier o tipo de 
  daños y perjuicios de carácter comercial, económico o empresarial—, y los daños y perjuicios derivados de cualesquiera 
  reclamaciones presentadas por terceros, todo ello con independencia de la forma en la que se hayan producido los daños 
  y perjuicios y de la causa en la que se pretenda basar la atribución de responsabilidad.
  1. La Plataforma está destinada para uso individual de profesionales sanitarios u hospitalarios o bien para personas 
  individuales que actúen bajo su propia responsabilidad. Los Suscriptores y/o Usuarios harán uso del Servicio bajo su 
  propia y exclusiva responsabilidad médica. En ningún caso será responsabilidad de **ALMA IT SYSTEMS**, renunciando 
  expresamente el Suscriptor y/o los Usuarios a toda reclamación relativa a los mismos, los daños y perjuicios sufridos 
  por el Suscriptor, los Usuarios y/o terceros, por un mal uso o abuso del Servicio —incluyendo en particular en este 
  capítulo, a título meramente enunciativo y no limitativo, posibles defectos en la calidad de las imágenes y contenidos 
  médicos gestionados y almacenados por el Suscriptor y/o los Usuarios, cualesquiera resultados de diagnóstico médico, 
  etc.—, o que sean causados por fallos o defectos de los equipos, dispositivos, infraestructuras, recursos y/o 
  cualesquiera servicios de terceros —a título meramente enunciativo y no limitativo, los fallos de los servidores, 
  servicios de Internet y de comunicación de datos, etc.—, así como los daños que pudieran derivarse de la introducción 
  de virus, gusanos o cualquier otro tipo de malware o elemento informático que pueda resultar dañino, siendo 
  responsabilidad del Suscriptor y de los Usuarios disponer o implementar las herramientas adecuadas para la detección, 
  protección y desinfección de los mismos.
  1. Las referidas exclusiones de responsabilidad se aplicarán en la medida en que lo permita la legislación aplicable, 
  y en todo caso, de concluirse la existencia de responsabilidad a cargo de **ALMA IT SYSTEMS**, la misma se verá limitada 
  en total y como máximo al importe de la suscripción de la última anualidad abonada por el Suscriptor

#### 9. Otras estipulaciones

  1. Los términos y condiciones de esta **Licencia** únicamente podrán ser modificados previa aceptación expresa de **ALMA IT SYSTEMS**.
  1. En el caso de eventuales discrepancias en la interpretación de los términos de la presente **Licencia** en distintos idiomas, la versión en castellano primará sobre las demás.

### Jurisdicción y ley aplicable

  - Los Juzgados y Tribunales de Barcelona tendrán jurisdicción exclusiva para conocer y resolver cualesquiera disputas relacionadas con la presente **Licencia**, aceptando todas las partes someterse a los mismos, con renuncia expresa a cualquier otra jurisdicción que pudiera corresponderles.
  - Esta **Licencia** se regirá e interpretará de acuerdo con las leyes españolas.
`;
