import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import { Box, Button, Grid, Link, Typography } from '@material-ui/core';
import { useQueryParam, StringParam } from 'use-query-params';

import { TextFieldUI } from '../../componentsUI/TextField';
import { Form } from '../Common/styled/Form';
import { CREATE_USER } from '../../graphql/mutations';
import { RegisterTermsConditionsDialog } from './modal/RegisterTermsConditionsDialog';
import { PopoverContent, PopoverHeader, Popover, PopoverWrapper } from '../Common/styled/FloatingElement';
import Loading from '../Common/Loading';
import { CheckBoxUI } from '../../componentsUI/CheckBox';
import { PasswordFieldUI } from '../../componentsUI/PasswordField';
import { InnerSpanHTML } from '../Common/InnerHTML';
import { AlertUI } from '../../componentsUI/Alert';
import { capitalize } from '../../utils/StringUtils';
import useStyles from './styled/RegisterMakeStyles';

export const RegisterNewUserForm = ({ loading, hospital }) => {
  const { t } = useTranslation();
  const [serverError, setServerError] = useState(false);
  const [formError, setFormError] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [termsDisabled, setTermsDisabled] = useState(true);
  const [termsChecked, setTermsChecked] = useState(false);
  const [adAcceptance, setAdAcceptance] = useState(false);
  const [termsConditionsModal, setTermsConditionsModal] = useState(false);
  const [email] = useQueryParam('email', StringParam);
  const [patientUuid] = useQueryParam('patientUuid', StringParam);
  const [hospitalUuid] = useQueryParam('hospitalUuid', StringParam);
  const [signature] = useQueryParam('signature', StringParam);
  const [selectedPlan] = useQueryParam('selectedPlan', StringParam);
  const styles = useStyles();

  const [popover, setPopover] = useState(false);
  const togglePopover = () => setPopover(!popover);

  const redirectToPatientPlatform = () => {
    window.location.href = `${process.env.GATSBY_APP_TELEMEDICINE_URL}/dashboard/`;
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required.field')),
    surname: Yup.string().required(t('required.field')),
    email: Yup.string().email(t('invalid.email')).required(t('required.field')),
    password: Yup.string()
      .required(t('required.field'))
      .matches(/^(.{8,20})$/, t('password.not.valid.length'))
      .matches(/.*[0-9].*/, t('password.must.contain.number'))
      .matches(/.*[A-Z].*/, t('password.must.contain.upper.case'))
      .matches(/.*[a-z].*/, t('password.must.contain.lower.case')),
    passwordConfirmation: Yup.string().required(t('required.field')).oneOf([Yup.ref('password'), null], 'Passwords must match'),
    terms: Yup.boolean().oneOf([true], t('mandatory.accepted.field')),
    adAcceptance: Yup.boolean(),
  });

  const popTermsConditions = () => {
    setTermsConditionsModal(true);
    // setPopoverAnchorEl(event.currentTarget);
  };

  const handleCloseTermsConditions = () => {
    setTermsConditionsModal(false);
    // setPopoverAnchorEl(null);
  };

  const initialValues = {
    name: '',
    surname: '',
    email: email || '',
    password: '',
    passwordConfirmation: '',
    adAcceptance: false,
  };

  const [createUser] = useMutation(CREATE_USER, {
    onCompleted: () => {
      setDisabledSubmit(false);

      if (patientUuid) redirectToPatientPlatform();
      else if (selectedPlan) navigate(`/plans?selectedPlan=${selectedPlan}`);
      else navigate('/dashboard');
    },
  });

  const toggleTerms = () => {
    if (!termsDisabled) {
      setTermsChecked(!termsChecked);
    } else {
      togglePopover();
    }
  };

  const hidePopover = () => {
    setPopover(false);
  };
  const showPopover = () => {
    if (!termsDisabled) return;
    setPopover(true);
  };
  const openUncheckedConditions = () => {
    if (termsDisabled) {
      setTermsConditionsModal(true);
      return;
    }
    setTermsChecked(!termsChecked);
  };

  const toggleAdAcceptance = () => {
    setAdAcceptance(!adAcceptance);
  };

  const handleSubmit = async (values) => {
    setServerError(false);
    setFormError(false);
    if (!termsChecked) {
      setFormError('you.must.accept.terms.and.conditions');
      return;
    }
    setDisabledSubmit(true);
    try {
      await createUser({
        variables: {
          input: {
            name: values.name,
            surname: values.surname,
            username: values.email,
            email: values.email,
            password: values.password,
            adAcceptance,
            patientUuid,
            hospitalUuid,
            signature,
          },
        },
      });
    } catch (errors) {
      if (errors.graphQLErrors && errors.graphQLErrors.length) {
        setDisabledSubmit(false);
        setServerError(errors.graphQLErrors[0].message);
      }
    }
  };

  const requirementStatements = {
    first: `* <b>${t('mandatory.field')}</b>.`,
    second: `** ${t('password.requirements')}.`,
  };

  return (
    <Box className={styles.newUser}>
      <RegisterTermsConditionsDialog
        open={termsConditionsModal}
        onClose={handleCloseTermsConditions}
        termsDisabled={termsDisabled}
        setTermsDisabled={setTermsDisabled}
        setTermsChecked={setTermsChecked}
      />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form autoComplete="off" onSubmit={props.handleSubmit} className={styles.form}>

            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} className={styles.title}>
                  {!hospital && loading && <Loading />}
                  {hospital && hospital.name && (
                    <Typography variant="h5">
                      {t('sign.up.in')}
                      <strong>{` ${hospital && hospital.name}`}</strong>
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldUI
                    name="name"
                    label={`${t('name')} *`}
                    props={props}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldUI
                    name="surname"
                    label={`${t('surname')} *`}
                    props={props}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldUI
                    type="email"
                    name="email"
                    label={`${t('email')} *`}
                    props={props}
                    readonly={!!email}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <PasswordFieldUI
                    name="password"
                    label={`${t('password')} **`}
                    props={props}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <PasswordFieldUI
                    name="passwordConfirmation"
                    label={`${t('confirm.password')} **`}
                    props={props}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box>
                    <small className="mb-2"><InnerSpanHTML content={requirementStatements.first} /></small>
                    <br />
                    <small className="mb-2"><InnerSpanHTML content={requirementStatements.second} /></small>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={0} className={styles.conditions}>
                <Grid item xs={12} className={styles.terms}>

                  <PopoverWrapper>
                    <CheckBoxUI
                      name="terms"
                      props={{
                        ...props,
                        handleChange: openUncheckedConditions,
                        handleMouseEnter: showPopover,
                        handleMouseLeave: hidePopover,
                      }}
                      checked={termsChecked}
                    >
                      <Typography
                        component="span"
                        onClick={toggleTerms}
                        className={styles.label}
                      >
                        {`${t('i.read.accept.the')} `}
                      </Typography>
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid */}
                      <Link
                        href="#"
                        onClick={popTermsConditions}
                        className={styles.link}
                      >
                        {t('conditions.of.use').toLowerCase()}
                      </Link>
                    </CheckBoxUI>

                    <Popover className={popover ? 'show' : 'hide'}>
                      <PopoverHeader>
                        {t('acceptance.conditions')}
                      </PopoverHeader>
                      <PopoverContent>
                        {t('must.read.conditions')}
                      </PopoverContent>
                    </Popover>

                  </PopoverWrapper>

                </Grid>
                <Grid item xs={12}>
                  <CheckBoxUI
                    name="adAcceptance"
                    props={{ ...props, handleChange: toggleAdAcceptance }}
                    checked={adAcceptance}
                  >
                    <Typography
                      component="span"
                      onClick={toggleAdAcceptance}
                      className={styles.label}
                    >
                      <InnerSpanHTML content={t('agreement.ad.info')} />
                    </Typography>
                  </CheckBoxUI>
                </Grid>
              </Grid>
            </Box>

            {disabledSubmit && <Box><Loading /></Box>}

            <Box>
              <Box className="mt-5">
                {formError && <AlertUI title={capitalize(t('warning'))} severity="warning">{t(formError)}</AlertUI>}
                {serverError && <AlertUI title="Error" severity="error">{t(serverError)}</AlertUI>}
              </Box>
              <Box className="mt-4">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  className={styles.submit}
                >
                  {t('register')}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
