import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    maxWidth: 675,
    marginLeft: 'auto!important',
    marginRight: 'auto!important',
  },
  paper: {
    width: '100%',
    height: '100%',
    padding: 24,
    overflowY: 'scroll',
    overflowX: 'hidden',
    boxSizing: 'border-box',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& > .MuiBox-root': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    '@media (max-width:768px)': {
      paddingLeft: 16,
      paddingRight: 16,
    },
    '@media (max-width:480px)': {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  image: {
    display: 'block!important',
    margin: 16,
    marginLeft: 'auto!important',
    marginRight: 'auto!important',
    width: 250,
    maxWidth: '100%',
    '@media (max-width:480px)': {
      width: 200,
    },
  },
  title: {
    marginTop: 16,
    fontWeight: 700,
    '@media (max-width:600px)': {
      '& > h5': {
        textAlign: 'center',
      },
    },
  },
  newUser: {
    flexGrow: 10,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    margin: 0,
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiGrid-spacing-xs-2 > .MuiGrid-item': {
      paddingBottom: 0,
    },
  },
  label: {
    fontSize: '1.35rem',
    lineHeight: 1,
    cursor: 'pointer',
  },
  link: {
    fontSize: '1.35rem',
    lineHeight: 1,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  conditions: {
    marginTop: 24,
    '& .MuiFormControlLabel-root': {
      marginTop: -9,
      marginBottom: -6,
    },
  },
  terms: {
    '& div[class*="Popover-"]': {
      position: 'absolute',
      top: '100%',
      left: 0,
      marginLeft: -10,
      width: 'calc(100% - 300px)',
      minWidth: 275,
      backgroundColor: 'cornsilk',
      border: '1px solid',
      borderColor: 'burlywood',
      borderRadius: 6,
      '& div[class*="PopoverContent-"]': {
        fontSize: '.9em',
      },
    },
  },
  submit: {
    minWidth: 200,
    float: 'right',
    marginBottom: 8,
  },
}));

export default useStyles;
